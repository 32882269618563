import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

import "../pages/style.css";
import Logo from "../images/Ramp7.svg";

export default function Impressum(props) {

  const location = useLocation();


  const [info, setInfo] = React.useState();

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(
        "https://p665936.mittwaldserver.info/api/api/singletons/get/ramp_seven?token=a8ccf49b48731963a832d84fe9e7d5",
        {
          method: "get",
          headers: { "Content-Type": "application/json" },
        }
      );
      await response.json().then(function (response) {
        setInfo(response);
      });
    };
    fetchData();
  }, []);

  if (!info) return null;

  return (
    <React.Fragment>
      {window.location.pathname.substring(0, 4) === "/de/" && (
        <div className="header padding-LR">
          <div className="LogoHolder">
            <Link
              to="/de/"
              className=""
              alt="Ramp Seven | Reviewing & Monitoring Projects"
              title="Ramp Seven | Reviewing & Monitoring Projects"
            >
              <img
                className="site-logo"
                width="300"
                height="90"
                src={Logo}
                alt="Ramp Seven | Reviewing & Monitoring Projects"
                title="Ramp Seven | Reviewing & Monitoring Projects"
              />
            </Link>
          </div>
          <div className="FormHolder">
            <Link to="/en/" className="Btn" alt="English" title="English">
              English
            </Link>

            <a
              href="https://app.ramp7.com"
              className="Btn"
              title="Ramp7 Login"
              alt="Ramp7 Login"
            >
              Login
            </a>
          </div>
        </div>
      )}

      {window.location.pathname.substring(0, 4) === "/en/" && (
        <div className="header padding-LR">
          <div className="LogoHolder">
            <Link
              to="/en/"
              className=""
              alt="Ramp Seven | Reviewing & Monitoring Projects"
              title="Ramp Seven | Reviewing & Monitoring Projects"
            >
              <img
                className="site-logo"
                width="300"
                height="90"
                src={Logo}
                alt="Ramp Seven | Reviewing & Monitoring Projects"
                title="Ramp Seven | Reviewing & Monitoring Projects"
              />
            </Link>
          </div>
          <div className="FormHolder">
            <Link
              to="/de/"
              className="Btn"
              alt="Deutschland"
              title="Deutschland"
            >
              deutsch
            </Link>

            <a
              href="https://app.ramp7.com"
              className="Btn"
              title="Ramp7 Login"
              alt="Ramp7 Login"
            >
              Login
            </a>
          </div>
        </div>
      )}
    </React.Fragment>
  );
}
